/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
// import './styles/app.css';

import * as bootstrap from "bootstrap";
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

console.log("Erfolgreich sdfsdfsd!");

// window.alert(`window.innerWidth: ${window.innerWidth} - window.innerHeight: ${window.innerHeight}`);
// window.alert(`window.screen.width: ${window.screen.width} - window.screen.height: ${window.screen.height}`);


const galleryElement = document.getElementById("gallery");

if (galleryElement) {
    lightGallery(galleryElement, {
        plugins: [lgZoom, lgThumbnail],
        speed: 500,
        download: false,
        zoom: true,
        thumbnail: true
    });
}

document.addEventListener("DOMContentLoaded", function() {
    document.querySelectorAll("table tbody tr").forEach(row => {
        row.addEventListener("click", function() {

            
            console.log("EventListener");


            const url = this.getAttribute("data-href");
            if (url) {
                window.location.href = url;
            }
        });
    });
});


console.log("Erfolgreich js geladen!");
